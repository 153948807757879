let formSubmit = document.querySelector('form.contact button[type=submit]');
formSubmit && formSubmit.addEventListener('click', function (event) {
    event.preventDefault();

    let form = document.querySelector('form.contact');

    if (form.checkValidity()) {
        if (document.querySelector('#response')) {
            grecaptcha.ready(() => {
                // Public API Key
                grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_PUBLIC, {
                    action: 'submit'
                }).then(token => {
                    document.querySelector('#response').value = token;
                    form.submit();
                });
            });
        } else {
            console.error('Input Response is missing');
        }
    } else {
        form.classList.add('was-validated');
    }
});