tarteaucitron.init({
	"hashtag": "#cookies",
	"highPrivacy": true,
	"orientation": "middle",
	"adblocker": false,
	"showAlertSmall": false,
	"showIcon": false,
	"cookieslist": true,
	"removeCredit": true,
	"useExternalCss": true
});
// tarteaucitron.user.gtagUa = import.meta.env.VITE_GTAG;
// tarteaucitron.user.analyticsAnonymizeIp = true;
// (tarteaucitron.job = tarteaucitron.job || []).push('gtag');

tarteaucitron.user.googletagmanagerId = import.meta.env.VITE_GTAG;
(tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager')