import './bootstrap.js';

// import '../laravel-cms/js/app.js'
import '../laravel-cms/js/recaptcha.js'
import '../laravel-cms/scss/app.scss';
import '../laravel-commerce/js/app.js'
// import '../laravel-core/js/front/app.js'

import './jquery-global.js';

// import * as bootstrap from 'bootstrap'

import './creative-menu/script.js';
import './creative-menu/style.css';

import 'slick-carousel';
import './slick/script.js';
import './slick/slick.css';
import './slick/slick-theme.css';

import './bootstrap/bootstrap-input-spinner.js';

import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import './jquery.fancybox/style.css';

import '../laravel-core/js/front/tarteaucitron/tarteaucitron.import.js';
import './tarteaucitron/script.js';

import '../scss/app.scss';
import '../scss/_custom.scss';

// import '../laravel-core/js/front/tarteaucitron/tarteaucitron.import.js';

import.meta.glob([
    '../medias/images/**/*.jpg',
    '../medias/images/**/*.png',
    '../medias/images/**/*.svg',
    '../fonts/**',
    '../laravel-commerce/medias/images/produits/default.jpg',
]);

$(function() {
    $('input[type="number"]').inputSpinner();
});

function initCartOptions() {
    document.querySelectorAll('.plaque-de-porte-plaque').forEach(select => {
        select.addEventListener('change', (event) => {
            event.preventDefault();
    
            if (select.id == 'options_plaque-de-porte_plaque_5') {
                document.querySelector('.plaque_5').style.display = 'block';
                document.querySelector('.plaque_5 input').required = true;
            } else {
                document.querySelector('.plaque_5').style.display = 'none';
                document.querySelector('.plaque_5 input').required = false;
            }
        });
    })

    document.querySelectorAll('.recouvrement-type').forEach(select => {
        select.addEventListener('change', (event) => {
            event.preventDefault();
    
            if (select.id == 'options_recouvrement_type_1') {
                document.querySelector('.type_1').style.display = 'block';
                document.querySelector('.type_1 input').required = true;
            } else {
                document.querySelector('.type_1').style.display = 'none';
                document.querySelector('.type_1 input').required = false;
            }
        });
    })
}
window.initCartOptions = initCartOptions;

/* CMS */

import '../scss/app.scss';

window.onload = () => {
	//
	// js pour désactiver l'action des liens avec juste une ancre
	//
    document.querySelectorAll('a[href="#"]').forEach((el) => {
        el.addEventListener("click", (event) => {
            event.preventDefault();
        })
    })

	//
	// js pour la réécriture des emails
	//
    document.querySelectorAll('.obfuscated').forEach((el) => {
        alert(el.innerHTML);
        el.innerHTML = el.innerHTML.replace(new RegExp("{AT}", 'g'), '@').replace(new RegExp("{DOT}", 'g'), '.');
		el.classList.remove("opacity-0");
    })

    //
	// js pour afficher/masquer le bouton pour retourner en haut de page
	//
	if(document.querySelector('.scrollto')) {
		var prevScrollpos = window.pageYOffset;

		window.onscroll = function() {
			if(window.outerHeight >= 356) {
				var currentScrollPos = window.pageYOffset;

				if(prevScrollpos <= currentScrollPos || currentScrollPos <= 0) {
                    document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '';
				} else {
                    if (window.outerHeight >= 448) {
                        document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '24px';
                    } else {
                        document.querySelector('body:not(.show-nav) .scrollto').style.bottom = '62px';
					}
				}

				prevScrollpos = currentScrollPos;
			}
		}
	}


	//
	// js pour placer le bouton pour retourner en haut de page
	//
    function displayRetour() {
        let scrollTo = document.querySelector('.scrollto');
        if (scrollTo) {
            let marginLeft = document.querySelector('body > div').clientWidth - scrollTo.clientWidth.toFixed(0)

            scrollTo.style.marginLeft = marginLeft + 'px';
            scrollTo.classList.remove("opacity-0");
		}
	}

	displayRetour();

    addEventListener("resize", () => {
        displayRetour();
    });

    var video = document.getElementById('video')
    if (video) {
        video.addEventListener('show.bs.modal', function (event) {
            let content = '<iframe src="https://www.youtube.com/embed/RQljy5N55ns" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="d-block w-100"></iframe>'
            document.getElementById('video-content').innerHTML = content
        })
    }
}
